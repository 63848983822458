import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    Skeleton,
    SkeletonText,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import CourseCard, {
    CourseCardProps,
} from '../../components/courses/CourseCardV2';
import CardCarousel from '../../components/generic/CardCarousel';
import { flushSync } from 'react-dom';
import { getCourseCardSize } from '../../components/courses/CourseCardV2/utils';
import ActivityCard from '../CourseOverviewPageV2/ActivityCard/ActivityCard';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { activityCardHeight } from '@recourseai/components/src/theme/consts';

export const COURSE_CARD_SPACING = 6;

interface SectionProps {
    heading: string | JSX.Element;
    subHeading?: string | JSX.Element;
    isLoaded: boolean;
    data?: Course[] | Activity[];
    noSkeletonItems?: boolean;
    wrapContent?: boolean;
    skeletonItemSize?: any;
}

export const Section: React.FC<SectionProps> = ({
    heading,
    subHeading,
    isLoaded,
    data,
    skeletonItemSize,
    noSkeletonItems = false,
    wrapContent,
    children,
}) => {
    /* The skeleton has a predefined layout but the actual container might be different, e.g. CardCarousel */
    const [showSkeleton, setShowSkeleton] = useState(!noSkeletonItems);
    const noOfSkeletonItems = useBreakpointValue([1, 2, 3, 3, 4, 5]);

    const sectionItems = useMemo(() => {
        if (data) {
            return data.slice(0, noOfSkeletonItems);
        }
        return Array(noOfSkeletonItems).fill(undefined);
    }, [data, noOfSkeletonItems]);

    return (
        <Box w='100%'>
            <SkeletonText
                noOfLines={1}
                skeletonHeight='1.1rem'
                w={!isLoaded ? '10rem' : undefined}
                mb={!isLoaded ? 3 : undefined}
                isLoaded={isLoaded}
            >
                <Heading size='md' as='h3' fontFamily='Roboto'>
                    {heading}
                </Heading>
            </SkeletonText>
            <SkeletonText
                noOfLines={1}
                skeletonHeight='0.9rem'
                w={!isLoaded ? '14rem' : undefined}
                mb={!isLoaded ? 3 : undefined}
                isLoaded={isLoaded}
            >
                <Text mb={2}>{subHeading}</Text>
            </SkeletonText>
            {showSkeleton && !isLoaded ? (
                <Flex
                    gap={COURSE_CARD_SPACING}
                    flexWrap={wrapContent ? 'wrap' : 'nowrap'}
                    overflow={wrapContent ? 'hidden' : undefined}
                >
                    {sectionItems.map((course, i) => (
                        <Skeleton
                            key={i}
                            isLoaded={isLoaded}
                            flexShrink={0}
                            {...skeletonItemSize}
                            onAnimationEnd={() => setShowSkeleton(false)}
                        />
                    ))}
                </Flex>
            ) : (
                children
            )}
        </Box>
    );
};

export default Section;

type StandardSectionProps = Pick<
    SectionProps,
    'heading' | 'subHeading' | 'isLoaded'
> & { courseCardVariant: CourseCardProps['variant'] };

export const CarouselSectionActivities: React.FC<Omit<
    StandardSectionProps,
    'courseCardVariant'
> & {
    activities: Activity[] | undefined;
}> = ({ heading, subHeading, activities: activitiesProp, isLoaded }) => {
    const { activityId } = useParams<{ activityId?: string }>();
    const sliderRef = useRef<Slider>(null);

    const history = useHistory();
    const activeActivityMode =
        (history.location.state as any)?.activeActivityMode || null;

    let highlightedActivity: Activity | undefined = undefined;

    const activities = activitiesProp ? [...activitiesProp] : undefined;
    if (activityId && activities) {
        const highlightedActivityIndex = activities?.findIndex(
            activity => activity.id.toString() === activityId,
        );
        if (highlightedActivityIndex !== -1) {
            highlightedActivity = activities[highlightedActivityIndex];
            activities?.splice(highlightedActivityIndex, 1);
        }
    }

    return (
        <Section
            isLoaded={isLoaded}
            heading={heading}
            subHeading={subHeading}
            data={activities}
            skeletonItemSize={{ width: 300, height: activityCardHeight }}
        >
            <CardCarousel
                key={highlightedActivity?.id}
                spacing={COURSE_CARD_SPACING}
                ref={sliderRef}
            >
                {highlightedActivity && [
                    <ActivityCard
                        key={highlightedActivity.id}
                        activity={highlightedActivity}
                        studentActivity={highlightedActivity.student_activity}
                        isActive={activeActivityMode === highlightedActivity.id}
                        onStartActivityMode={() => {
                            sliderRef.current?.slickGoTo(0, true);
                        }}
                    />,
                    <Divider
                        key='divider'
                        opacity={0.8}
                        height={activityCardHeight}
                        width='0 !important' // otherwise overwritten in the carousel by react slick
                        borderColor='brand.black.500'
                        orientation='vertical'
                    />,
                ]}
                {activities?.map(activity => (
                    <ActivityCard
                        key={activity.id}
                        activity={activity}
                        studentActivity={activity.student_activity}
                        isActive={activeActivityMode === activity.id}
                        onStartActivityMode={() => {
                            sliderRef.current?.slickGoTo(0, true);
                        }}
                    />
                ))}
            </CardCarousel>
        </Section>
    );
};

export const CarouselSectionCourses: React.FC<StandardSectionProps & {
    courses: Course[] | undefined;
}> = ({ heading, subHeading, courseCardVariant, courses, isLoaded }) => (
    <Section
        isLoaded={isLoaded}
        heading={heading}
        subHeading={subHeading}
        data={courses}
        skeletonItemSize={getCourseCardSize(courseCardVariant)}
    >
        <CardCarousel spacing={COURSE_CARD_SPACING}>
            {courses?.map((item, i) => (
                <CourseCard
                    key={i}
                    course={item as Course}
                    variant={courseCardVariant}
                />
            ))}
        </CardCarousel>
    </Section>
);

export const CollapsibleSection: React.FC<StandardSectionProps & {
    forceOpen?: boolean;
    courses: Course[] | undefined;
}> = ({
    // Backdoor for parent to open it after it is mounted
    forceOpen,
    heading,
    subHeading,
    courseCardVariant,
    courses,
    isLoaded,
}) => {
    const [showAll, setShowAll] = useState(forceOpen);
    useEffect(() => setShowAll(forceOpen), [forceOpen]);

    const [doAllCoursesFitInOneRow, setDoAllCoursesFitInOneRow] = useState<
        boolean | undefined
    >(undefined);

    const resizeObserver = useRef(
        new ResizeObserver(entries => {
            const elem = entries[0];
            if (
                parseInt(getCourseCardSize(courseCardVariant).height) <
                elem.target.scrollHeight
            ) {
                setDoAllCoursesFitInOneRow(false);
            } else {
                setDoAllCoursesFitInOneRow(true);
            }
        }),
    );

    const headingRef = useRef<HTMLDivElement>(null);
    const containerRefCallback = useCallback((node: HTMLDivElement | null) => {
        if (node) {
            resizeObserver.current.observe(node);
        } else {
            resizeObserver.current.disconnect();
        }
    }, []);

    return (
        <Section
            isLoaded={isLoaded}
            heading={<Box ref={headingRef}>{heading}</Box>}
            subHeading={subHeading}
            data={courses}
            skeletonItemSize={getCourseCardSize(courseCardVariant)}
        >
            <Flex
                ref={containerRefCallback}
                gap={COURSE_CARD_SPACING}
                justify='start'
                wrap='wrap'
                height={
                    showAll || doAllCoursesFitInOneRow
                        ? undefined
                        : getCourseCardSize(courseCardVariant).height
                }
                overflow={
                    showAll || doAllCoursesFitInOneRow ? undefined : 'hidden'
                }
            >
                {courses?.map((item, i) => (
                    <CourseCard
                        key={i}
                        course={item as Course}
                        variant={courseCardVariant}
                    />
                ))}
            </Flex>
            {!doAllCoursesFitInOneRow && (
                <Center my={3}>
                    <Button
                        onClick={e => {
                            flushSync(() => {
                                setShowAll(show => !show);
                            });
                            if (!showAll && headingRef.current) {
                                headingRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }
                        }}
                        size='lg'
                        bgColor='white'
                    >
                        {showAll
                            ? 'Show less'
                            : `Show all (${courses?.length})`}
                    </Button>
                </Center>
            )}
        </Section>
    );
};
