import React, {
    Fragment,
    useCallback,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { IconButton, useMergeRefs } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import useSize from '@react-hook/size';

import '../../slick-carousel/slick.css';
import '../../slick-carousel/slick-theme.css';

export const PrevArrow: React.FC<CustomArrowProps> = props => {
    return props.currentSlide === undefined ||
        props.currentSlide === 0 ? null : (
        <IconButton
            aria-label={'Previous'}
            borderRadius='full'
            colorScheme='brand.black'
            opacity={0.7}
            _hover={{
                opacity: 0.9,
            }}
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={props.onClick}
            position='absolute'
            top='50%'
            left='-20px'
            transform='translateY(-50%)'
            zIndex={10}
        />
    );
};

export const NextArrow: React.FC<CustomArrowProps> = props => {
    return props.currentSlide === undefined ||
        props.slideCount === undefined ||
        props.currentSlide === props.slideCount - 1 ? null : (
        <IconButton
            aria-label={'Next'}
            borderRadius='full'
            colorScheme='brand.black'
            opacity={0.7}
            _hover={{
                opacity: 0.9,
            }}
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={props.onClick}
            position='absolute'
            top='50%'
            right='-20px'
            transform='translateY(-50%)'
            zIndex={10}
        />
    );
};

const CardCarousel: React.FC<{
    spacing?: number | string;
    ref?: React.Ref<Slider>;
}> = React.forwardRef(({ spacing, children }, ref) => {
    const sliderRef = useRef<Slider>(null);
    const mergedRef = useMergeRefs(ref, sliderRef);

    const sliderListSize = useSize(
        sliderRef.current?.innerSlider?.list ?? null,
    );

    const [isLastItemInView, setIsLastItemInView] = useState(false);
    const [itemsInView, setItemsInView] = useState<number>(0);

    const getCarouselRenderDetails = useCallback((): [
        boolean | undefined,
        number | undefined,
    ] => {
        if (sliderRef.current) {
            const list = sliderRef.current.innerSlider?.list;
            const lastItem = list?.firstElementChild?.lastElementChild;

            if (lastItem && list) {
                const [lastItemRect, listRect] = [lastItem, list].map(e =>
                    e.getBoundingClientRect(),
                );
                const lastElementInView = lastItemRect.right <= listRect.right;

                const noItemsInView =
                    lastItemRect.width > 0
                        ? Math.floor(listRect.width / lastItemRect.width)
                        : 0;

                return [lastElementInView, noItemsInView];
            }
        }

        return [undefined, undefined];
    }, []);

    useLayoutEffect(() => {
        if (
            sliderRef.current &&
            sliderRef.current.innerSlider?.list?.firstElementChild &&
            spacing
        ) {
            (sliderRef.current.innerSlider.list
                .firstElementChild as HTMLElement).style.gap =
                typeof spacing === 'number'
                    ? `var(--chakra-space-${spacing})`
                    : spacing;
        }
    }, [spacing]);

    useLayoutEffect(() => {
        const [lastElementInView, noItemsInView] = getCarouselRenderDetails();
        if (lastElementInView !== undefined && noItemsInView !== undefined) {
            setIsLastItemInView(lastElementInView);
            setItemsInView(noItemsInView);
        }
    }, [sliderListSize, getCarouselRenderDetails]);

    return (
        <Slider
            ref={mergedRef}
            variableWidth
            infinite={false}
            slidesToScroll={itemsInView > 0 ? itemsInView : undefined}
            draggable={false}
            dots={false}
            afterChange={() => {
                const [lastElementInView] = getCarouselRenderDetails();
                if (lastElementInView !== undefined) {
                    setIsLastItemInView(lastElementInView);
                }
            }}
            onReInit={() => {
                if (itemsInView === undefined) {
                    const [
                        lastElementInView,
                        noItemsInView,
                    ] = getCarouselRenderDetails();
                    if (
                        lastElementInView !== undefined &&
                        noItemsInView !== undefined
                    ) {
                        setIsLastItemInView(lastElementInView);
                        setItemsInView(noItemsInView);
                    }
                }
            }}
            prevArrow={<PrevArrow />}
            nextArrow={!isLastItemInView ? <NextArrow /> : <Fragment />}
        >
            {/*
                Note that the react-slick library sets keys based on indices (ignoring the ones passed to the children)
                https://github.com/akiran/react-slick/issues/2005
            */}
            {children}
        </Slider>
    );
});

CardCarousel.displayName = 'CardCarousel';

export default CardCarousel;
