import React, { Fragment } from 'react';
import { Box, Button, HStack, VStack, Text, Spacer } from '@chakra-ui/react';
import IndexNumber from './IndexNumber';
import ExpandableText from '../../../components/generic/ExpandableText';
import { isExercise, isWritingAssignment } from '../../../utils/activityUtils';
import ModalOpener from '../../../components/generic/ModalOpener';
import { hasAttemptInProgress } from './utils';
import ActivityIcon, {
    ActivityImage,
} from '../../../components/activities/ActivityIcon';
import { ActivityPresentationProps } from './DetailedView';
import { activityCardHeight } from '@recourseai/components/src/theme/consts';
import WritingAssignmentLabel from './WritingAssignmentLabel';

export default function CompactView({
    activity,
    scrollRef,
    index,
    isHighlighted,
    studentActivity,
    cancelAttemptMutation,
    startActivity,
    hasRemainingAttemptsToday,
    hasRemainingTotalAttempts,
    course,
    completedAttempts,
    insightsModal,
}: ActivityPresentationProps) {
    return (
        <VStack
            id={`activity-card_${activity.id}`}
            ref={scrollRef}
            borderRadius='15px'
            bg='white'
            px={5}
            py={6}
            alignItems='top'
            spacing={4}
            w='300px'
            minH={activityCardHeight}
            onClick={course ? undefined : () => startActivity()}
            _hover={{ cursor: course ? 'none' : 'pointer' }}
        >
            <HStack gap='10px'>
                <ActivityIcon activity={activity} boxSize='25px' />
                <Text fontWeight='bold' mb={0} isTruncated maxW='300px'>
                    {activity.title}
                </Text>
            </HStack>
            <Box position='relative'>
                <ActivityImage activity={activity} />
                {index && (
                    <Box position='absolute' top='10px' left='10px'>
                        <IndexNumber
                            activity={activity}
                            index={index}
                            isActive={isHighlighted}
                        />
                    </Box>
                )}
            </Box>
            <ExpandableText noOfLines={2} lineHeight='1.3em' minH='60px'>
                <Box
                    lineHeight='1.3em'
                    mb='-1rem' // Countering the default 1rem padding on p elements
                    dangerouslySetInnerHTML={{
                        __html: activity.description,
                    }}
                />
            </ExpandableText>
            {!activity.writing_assignment?.submitted_at && ( // We don't support reattempts for writings
                <Button
                    colorScheme='brand.black'
                    h='50px'
                    w='100%'
                    _hover={{ color: 'white' }}
                    onClick={e => {
                        if (!course) {
                            // Otherwise we call start activity again on the vstack (parent component)
                            e.stopPropagation();
                        }
                        startActivity();
                    }}
                    disabled={
                        cancelAttemptMutation.isLoading ||
                        (isExercise(activity) &&
                            !hasAttemptInProgress(activity, studentActivity) &&
                            (!hasRemainingAttemptsToday ||
                                !hasRemainingTotalAttempts))
                    }
                >
                    {hasAttemptInProgress(activity, studentActivity)
                        ? 'Resume'
                        : studentActivity?.attempts.length
                        ? 'Reattempt'
                        : 'Start activity'}
                </Button>
            )}
            {isWritingAssignment(activity) &&
                activity.writing_assignment?.submitted_at && (
                    <Fragment>
                        <Spacer />
                        <WritingAssignmentLabel
                            writingAssignment={activity.writing_assignment}
                        />
                    </Fragment>
                )}
            {course && (
                <HStack mt='5px!important'>
                    {!activity.writing_assignment &&
                    hasAttemptInProgress(activity, studentActivity) && ( // We don't support cancellations for writings
                            <Button
                                variant='outline'
                                h='50px'
                                w='100%'
                                colorScheme='brand.black'
                                onClick={() =>
                                    cancelAttemptMutation.mutate({
                                        course,
                                        activity,
                                        attempt: studentActivity!.attempts.find(
                                            a => a.completed_datetime === null,
                                        )!,
                                    })
                                }
                                isLoading={cancelAttemptMutation.isLoading}
                                loadingText='Cancelling'
                            >
                                Cancel
                            </Button>
                        )}
                    {completedAttempts.length > 0 && (
                        <ModalOpener Modal={insightsModal}>
                            <Button
                                h='50px'
                                w='100%'
                                variant='outline'
                                colorScheme='brand.black'
                            >
                                View feedback
                            </Button>
                        </ModalOpener>
                    )}
                </HStack>
            )}
        </VStack>
    );
}
